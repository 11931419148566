import { IEvent, Object as IObject } from "fabric/fabric-impl";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "../state/contexts/CanvasContext";
import { useDesignerDispatch, useDesignerSelector } from "../state/store";
import { selectDesignInformation } from "../state/slices/designInformation";
import getDefaultZoom from "../features/Canvas/functions/getDefaultZoom";
import { Tool } from "../state/models/ICanvasTool";
import { Textbox } from "fabric";

const useCamera = () => {
  const canvas = useContext(CanvasContext);
  const { canvasSettings } = useDesignerSelector(selectDesignInformation);
  const hasInitialized = useRef(false);
  const isDragging = useRef(false);
  const lastPosX = useRef<number>();
  const lastPosY = useRef<number>();
  const defaultZoom = getDefaultZoom(
    canvasSettings?.width,
    canvasSettings?.height
  );

  const selectionDisabledRef = useRef(false);
  const selectableObjectsRef = useRef<IObject[]>([]);
  const isOnCanvasRef = useRef(true);
  const panKeyDownRef = useRef(false);

  const panTimeoutRef = useRef<NodeJS.Timeout>();

  const [zoom, setZoom] = useState(defaultZoom);
  const [cameraPos, setCameraPos] = useState({ x: 1, y: 1 });

  const [defaultCamera, setDefaultCamera] = useState({
    zoom: defaultZoom,
    cameraX: 1,
    cameraY: 1,
    viewPort: [0],
  });

  function zoomIn() {
    if (!canvas) return;
    setZoom(zoom + 0.1);
    canvas.zoomToPoint(
      { y: defaultCamera.cameraY, x: defaultCamera.cameraX },
      zoom + 0.1
    );
    canvas.renderAll();
  }

  function zoomOut() {
    if (!canvas) return;
    if (zoom - 0.1 < 0) return;
    setZoom(zoom - 0.1);
    canvas.zoomToPoint(
      { y: defaultCamera.cameraY, x: defaultCamera.cameraX },
      zoom - 0.1
    );
    canvas.renderAll();
  }

  function resetZoom() {
    if (!canvas) return;
    // now we use that stored viewport to recenter and reset the zoom of our canvas.
    setZoom(defaultCamera.zoom);
    canvas.setViewportTransform(defaultCamera.viewPort);
    canvas.renderAll();
  }

  function centerCamera() {
    setCameraPos({ x: defaultCamera.cameraX, y: defaultCamera.cameraY });
  }

  function handleWheelZoom(e: IEvent<WheelEvent>) {
    e.e.preventDefault();
    if (!canvas || (!e.e.shiftKey && !e.e.ctrlKey)) return;
    const delta = e.e.deltaY;
    let canvasZoom = canvas.getZoom() * 0.999 ** delta;
    if (canvasZoom > 20) canvasZoom = 20;
    if (canvasZoom < 0.01) canvasZoom = 0.01;
    canvas.zoomToPoint({ x: e.e.offsetX, y: e.e.offsetY }, canvasZoom);
    canvas.renderAll();
    setZoom(canvasZoom);

    e.e.preventDefault();
    e.e.stopPropagation();
  }

  function initCamera() {
    if (!canvas || hasInitialized.current) return;
    canvas.off("mouse:wheel", handleWheelZoom);
    // // @ts-ignore
    // canvas.off("mouse:down", startPan);
    // // @ts-ignore
    // canvas.off("mouse:up", endPan);
    // // @ts-ignore
    // canvas.off("mouse:drag", panCamera);
    window.removeEventListener("resize", resizeCanvas);
    window.removeEventListener("fullscreen", resizeCanvas);

    // window.removeEventListener("keydown", handlePanKeyDown);
    // window.removeEventListener("keyup", handlePanKeyUp);

    hasInitialized.current = true;

    canvas.on("mouse:wheel", handleWheelZoom);

    // canvas.on("mouse:down", startPan);
    // canvas.on("mouse:up", endPan);
    canvas.on("mouse:move", panCamera);

    // canvas.on("mouse:over", (e) => {
    //   console.log(e.target);
    //   if (!e.target) {
    //     isOnCanvasRef.current = true;
    //   }
    // });
    // canvas.on("mouse:out", (e) => {
    //   if (!e.target) {
    //     isOnCanvasRef.current = false;
    //   }
    // });

    window.addEventListener("keydown", handlePanKeyDown);
    window.addEventListener("keyup", handlePanKeyUp);

    window.addEventListener("resize", resizeCanvas);
    window.addEventListener("fullscreen", resizeCanvas);

    const tl = canvas.vptCoords?.tl ?? { x: 0, y: 0 };

    const center = canvas.getVpCenter();

    const background = canvas
      .getObjects()
      .find((obj) => obj.name === "background");
    if (background) {
      const bgCenter = background.getCenterPoint();

      canvas.relativePan({
        x: center.x - bgCenter.x,
        y: center.y - bgCenter.y,
      });
      canvas.zoomToPoint({ x: center.x, y: center.y }, zoom);
    }

    // We need to capture the current viewport in order to reset in the future.
    const viewport = canvas.viewportTransform;

    if (viewport) {
      setZoom(defaultZoom);
      setDefaultCamera({
        ...defaultCamera,
        zoom: defaultZoom,
        cameraX: center.x,
        cameraY: center.y,
        viewPort: viewport,
      });
    }
  }

  function handlePanKeyUp(e: KeyboardEvent) {
    if (e.code === "Space") {
      panKeyDownRef.current = false;
      lastPosX.current = undefined;
      lastPosY.current = undefined;
    }
  }

  function handlePanKeyDown(e: KeyboardEvent) {
    if (canvas) {
      const selectedObject = canvas.getActiveObject() as Textbox;
      if (
        selectedObject &&
        selectedObject.type?.includes("text") &&
        selectedObject.isEditing
      ) {
        panKeyDownRef.current = false;

        return;
      }

      if (e.code === "Space" && isOnCanvasRef.current) {
        const target = e.target as HTMLElement;

        if (target.nodeName !== "BODY") {
          return;
        }
        e.preventDefault();
        panKeyDownRef.current = true;
      }
    }
  }

  function startPan(e: IEvent<MouseEvent>) {
    if (!canvas) return;
    if (!isOnCanvasRef.current) {
      isOnCanvasRef.current = true;
    }
    if (!panKeyDownRef.current) return;
    if (e.transform) {
      e.transform.actionHandler = () => false;
    }
    isDragging.current = true;
    lastPosX.current = e.e.clientX;
    lastPosY.current = e.e.clientY;
  }

  function panCamera(evt: IEvent<MouseEvent>) {
    if (!canvas) return;

    const e = evt.e;
    if (!panKeyDownRef.current || !lastPosX.current || !lastPosY.current) {
      if (panTimeoutRef.current) {
        clearTimeout(panTimeoutRef.current);
      }
      panTimeoutRef.current = setTimeout(() => {
        if (canvas) {
          const pointer = canvas.getPointer(evt.e, true);
          lastPosX.current = pointer.x;
          lastPosY.current = pointer.y;
        }
      }, 1);
    }

    if (panKeyDownRef.current) {
      const viewPort = canvas.viewportTransform;
      if (viewPort && lastPosX.current && lastPosY.current) {
        viewPort[4] += e.clientX - lastPosX.current;
        viewPort[5] += e.clientY - lastPosY.current;
        canvas.requestRenderAll();
        lastPosX.current = e.clientX;
        lastPosY.current = e.clientY;
        canvas.setViewportTransform(viewPort);
      }
    }
  }
  function endPan(e: IEvent<MouseEvent>) {
    if (!canvas) return;

    isDragging.current = false;
    canvas.renderAll();
  }

  function resizeCanvas() {
    const parent = document.querySelector("._canvasDesignerColumn");
    if (parent && canvas) {
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      canvas.setWidth(width - 20);
      canvas.setHeight(height - 20);
      canvas.renderAll();
    }
  }

  useEffect(initCamera, [canvas]);

  return { zoomIn, zoomOut, resetZoom, centerCamera, zoom };
};

export default useCamera;
