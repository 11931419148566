import tw, { css } from "twin.macro";
import useCamera from "../../../hooks/useCamera";
import { PlusIcon, MinusIcon } from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";

const styles = {
  button: [
    tw`bg-glacier border-border border-solid border border-r-0 text-labels last:border-r hover:bg-border`,
  ],
  textButton: [tw`font-semibold`],
  container: [tw`flex items-center absolute bottom-4 right-4`],
};

const Zoom = () => {
  const { zoom, zoomIn, zoomOut, resetZoom } = useCamera();
  return (
    <div>
      <div css={styles.container}>
        <ToolButton
          small
          position="left"
          onClick={zoomIn}
          styles={styles.button}
        >
          <PlusIcon color={tw`fill-labels`} />
        </ToolButton>
        <ToolButton
          small
          position="center"
          onClick={zoomOut}
          css={styles.button}
        >
          <MinusIcon color={tw`fill-labels`} />
        </ToolButton>
        <ToolButton
          small
          position="right"
          styles={[...styles.button, ...styles.textButton]}
          onClick={resetZoom}
        >
          {Math.floor(zoom * 100)}%
        </ToolButton>
      </div>
    </div>
  );
};

export default Zoom;
