import RESOLUTION from "../../../constants/RESOLUTION";
import { ISafeAreaObject } from "../../../constants/Sizes";
import { Canvas } from "fabric/fabric-impl";
import { fabric } from "fabric";
import DesignerProductTypes from "../../../../data/models/DesignerProductTypes";
import { LetterFoldTypes } from "../../../state/slices/letterSettings";
import insertOverlayObjects from "./insertOverlayObjects";
import { createBleedClipPath } from "./createBackgroundClipPath";
export default function insertBackground(
  canvas: Canvas,
  sizeInformation: ISafeAreaObject,
  page: number,
  productType: DesignerProductTypes,
  envelopeType?: string,
  foldType?: LetterFoldTypes,
) {
  const existingBleed = canvas.getObjects().find((x) => x.name === "bleed");
  console.log("existing bleed", existingBleed);
  if (existingBleed) {
    const overlayObjects = canvas
      .getObjects()
      .filter(
        (x) =>
          x.name === "overlay" || x.name === "background" || x.name === "bleed",
      );
    canvas.remove(...overlayObjects);
  }

  const currentPage = sizeInformation.pages?.find((x) => x.pageNumber === page);
  const width = currentPage?.width ?? sizeInformation.width;
  const height = currentPage?.height ?? sizeInformation.height;
  const safeAreaInfo = currentPage?.safeArea ?? sizeInformation.safeArea;

  // create the white background rectangle

  const background = new fabric.Rect({
    width: (width - (sizeInformation.bleed ?? 0)) * RESOLUTION,
    height: (height - (sizeInformation.bleed ?? 0)) * RESOLUTION,
    fill: "white",
    strokeWidth: 0,
    name: "background",
    stroke: "black",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    perPixelTargetFind: true,
    strokeUniform: true,
  });

  const bleed = new fabric.Rect({
    width: width * RESOLUTION - 2,
    height: height * RESOLUTION - 2,
    stroke: "blue",
    strokeWidth: 2,
    name: "bleed",
    selectable: false,
    lockMovementX: true,
    strokeUniform: true,
    lockMovementY: true,
    fill: "rgba(0,0,0, 0)",
    perPixelTargetFind: true,
  });

  const safeArea = new fabric.Rect({
    width: safeAreaInfo.width * RESOLUTION - 2,
    height: safeAreaInfo.height * RESOLUTION - 2,
    stroke: "green",
    strokeUniform: true,
    strokeWidth: 2,
    strokeDashArray: [8, 8],
    name: "overlay",
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    fill: "rgba(0,0,0, 0)",
    perPixelTargetFind: true,
  });

  canvas.insertAt(background, 0, false);
  canvas.add(bleed);
  canvas.add(safeArea);
  canvas.centerObject(background);
  canvas.centerObject(bleed);
  canvas.centerObject(safeArea);

  if (sizeInformation.bleed) {
    const bleedLabel = new fabric.Text("Bleed", {
      fill: "blue",
      originX: "center",
      originY: "center",
      left: bleed.getCenterPoint().x,
      top: bleed.getCenterPoint().y - bleed.getScaledHeight() / 2 - 20,
      name: "overlay",
      fontSize: 26,
      evented: false,
      selectable: false,
      fontFamily: "Roboto",
    });
    canvas.insertAt(bleedLabel, 1, false);
  }

  const safeAreaLabel = new fabric.Text("Safe Area", {
    fill: "green",
    originX: "center",
    originY: "center",
    left: safeArea.getCenterPoint().x,
    top: safeArea.getCenterPoint().y - safeArea.getScaledHeight() / 2 - 20,
    name: "overlay",
    fontSize: 26,
    evented: false,
    selectable: false,
    fontFamily: "Roboto",
  });
  canvas.insertAt(safeAreaLabel, 3, false);
  canvas.renderAll();
  insertOverlayObjects(
    canvas,
    sizeInformation,
    page,
    productType,
    envelopeType,
    foldType,
  );
  // if the size has changed, we need to update the clip paths of every object
  if (
    existingBleed &&
    (existingBleed.width !== bleed.width ||
      existingBleed.height !== bleed.height)
  ) {
    const objectsWithBleedClip = canvas._objects.filter(
      (x) =>
        x.clipPath &&
        x.clipPath.width &&
        x.clipPath.width === existingBleed.width &&
        x.clipPath.height &&
        x.clipPath.height === existingBleed.height,
    );

    if (objectsWithBleedClip.length) {
      objectsWithBleedClip.forEach((obj) => {
        obj.clipPath = createBleedClipPath(canvas);
      });
    }
  }
}
