import React, { useContext, useEffect, useRef, useState } from "react";

import "./fontawesome.css";
import "./fonts/fa-regular-400.ttf";
import "./fonts/fa-solid-900.ttf";

import tw, { GlobalStyles as BaseStyle, css } from "twin.macro";
// import tw, { theme, GlobalStyles as BaseStyles } from "twin.macro";

import Canvas from "./features/Canvas/Canvas";

import CanvasWrapper from "./features/DesignerInterface/CanvasWrapper";
import Toolbars from "./features/DesignerInterface/Toolbars";
import { ICanvasDesignerProps } from "./models/ICanvasDesignerProps";
import {
  ISaveData,
  SaveDataDispatchContext,
} from "./state/contexts/SaveDataContext";

import Zoom from "./features/DesignerInterface/Zoom/Zoom";
import {
  resetDesignInformation,
  setDesignInformation,
} from "./state/slices/designInformation";
import { useDesignerDispatch } from "./state/store";

import { getAsync } from "../helpers/asyncFetch";
import { QrCodeRaw } from "../shared/QrCodeEditor/models/QrCode";
import { QrCodeStylingOptions } from "../shared/QrCodeEditor/types/QrCodeStylingTypes";
import Sizes from "./constants/Sizes";
import LoadingScreen from "./features/DesignerInterface/LoadingScreen";
import useLoadCanvasFonts from "./features/Fonts/useLoadCanvasFonts";
import QrCodeSelector from "./features/QrCodes/components/QrCodeSelector";
import useKeyboardShortcuts from "./hooks/useKeyboardShortcuts";
import { resetAssetLibrary } from "./state/slices/assetLibrary";
import { resetHistory } from "./state/slices/history";
import { resetLayers } from "./state/slices/layers";
import { resetProperties } from "./state/slices/objectProperties";
import { initializeQrCodes, setQrCodes } from "./state/slices/qrCode";
import { resetTools } from "./state/slices/toolSettings";
// import useFonts from "./fonts/useFonts";

const CanvasDesigner = (props: ICanvasDesignerProps) => {
  const dispatch = useDesignerDispatch();
  const saveDataDispatch = useContext(SaveDataDispatchContext);

  const container = useRef<HTMLDivElement>(null);

  const [initialized, setInitialized] = useState(false);
  const [canvasLoaded, setCanvasLoaded] = useState(false);

  const [disableShortcuts, setDisableShortcuts] = useState<boolean | string>(
    false,
  );

  const fontsLoaded = useLoadCanvasFonts(props.defaultCanvasData);

  useKeyboardShortcuts(disableShortcuts);
  function initCanvas() {
    const size = Sizes[props.size];
    const pages = size?.pages?.map((x) => ({ name: x.name }));
    if (props.defaultCanvasData) {
      const data: ISaveData = JSON.parse(props.defaultCanvasData);
      saveDataDispatch(data);
    }
    dispatch(
      setDesignInformation({
        canvasSettings: {
          width: size?.width ?? 6.25,
          height: size?.height ?? 4.5,
          overlay: props.size,
          defaultZoom: 1,
        },
        pageSettings: pages ?? props.pages,
        htmlVersionID: props.htmlVersionId,
        designID: props.designId,
        initialJson: props.defaultCanvasData,
        bAccountID: props.bAccountID,
        productType: props.productType,
      }),
    );
    getQrCodes();
    setInitialized(true);

    return () => {
      dispatch(resetTools());
      dispatch(resetLayers());
      dispatch(resetAssetLibrary());
      dispatch(resetHistory());
      dispatch(resetDesignInformation());
      dispatch(resetProperties());
      dispatch(initializeQrCodes({ qrCodes: [], qrCodeId: null }));
    };
  }

  async function getQrCodes() {
    const qrCodes = await getAsync<QrCodeRaw[]>(
      `/qr-codes?bAccountID=${props.bAccountID}`,
    );
    if (qrCodes) {
      dispatch(
        setQrCodes(
          qrCodes.map((x) => ({
            ...x,
            config: JSON.parse(x.config) as QrCodeStylingOptions,
          })),
        ),
      );
    }
  }

  useEffect(initCanvas, []);

  if (!initialized || !fontsLoaded)
    return (
      <div>
        <LoadingScreen mode={props.mode} />
      </div>
    );
  return (
    <React.Fragment>
      <BaseStyle />

      <CanvasWrapper mode={props.mode}>
        <Toolbars
          toggleShortcuts={setDisableShortcuts}
          mode={props.mode}
          onSaveCallback={props.onSaveCallback}
          forceDisabled={!initialized || !fontsLoaded}
        >
          <div
            className="_canvasDesignerRoot"
            css={
              props.mode !== "admin" && props.mode !== "internal"
                ? [
                    tw`flex-1 w-full relative`,
                    css`
                      width: 100%;
                      max-width: 100%;
                      height: calc(100vh - 76px);
                      overflow: hidden;
                    `,
                  ]
                : [
                    [
                      tw`flex-1 w-full relative`,
                      css`
                        width: 100%;
                        max-width: 100%;
                        overflow: hidden;
                        height: calc(100vh - 76px);
                        overflow: hidden;
                      `,
                    ],
                  ]
            }
            ref={container}
          >
            <Canvas loadCanvas={() => setCanvasLoaded(true)} />
            <Zoom />
            {canvasLoaded && <QrCodeSelector />}
          </div>
        </Toolbars>
      </CanvasWrapper>

      {!canvasLoaded && <LoadingScreen mode={props.mode} />}
    </React.Fragment>
  );
};

export default CanvasDesigner;
