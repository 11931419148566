enum DesignerSizes {
  FOUR_BY_SIX = "46",
  SIX_BY_EIGHT = "68",
  SIX_BY_ELEVEN = "611",
  LETTER = "811",
  BROCHURE = "BRO",
  SNAP = "811SNP",
}

export default DesignerSizes;
