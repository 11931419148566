import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { postAsync } from "../../../helpers/asyncFetch";
import { fetchClient } from "../../../helpers/fetchClient";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import useQuery from "../../../hooks/useQuery";
import Img from "../../../shared/BasicHTML/Img";
import LoadingWrapper from "../../../shared/LoadingWrapper";
import GridItem from "../../../shared/MaterialWrappers/GridItem";
import GridRow from "../../../shared/MaterialWrappers/GridRow";
import { DesignSize } from "../../Admin/models/DesignCreationModel";
import Design from "../../Designs/models/Design";
import ImageUpload from "../../Designs/UploadADesign/components/ImageUpload";
import SizeSelector from "../../Designs/UploadADesign/components/SizeSelector";
import { PricingTable } from "../DesignGallery/components/PricingTable";
import { PricingWithDescription } from "../DesignGallery/models/pricing";
import { getCookie } from "../helpers/cookies";
import { IntegratedContext } from "../layout/IntegratedLayout";

const proofOrderRecipient = {
  envelopeConfig: {
    type: "Regular",
  },
  firstName: "First",
  lastName: "Last",
  address: "123 Address St.",
  address2: "Apt. 1",
  city: "Clearwater",
  state: "FL",
  zipCode: "33765",
  extRefNbr: "",
  recipientDesignVariables: [],
};

function FormWrapper({
  isPortal = false,
  children,
}: {
  isPortal?: boolean;
  children: React.ReactNode;
}) {
  if (isPortal) {
    return (
      <Paper
        sx={{
          p: 2,
        }}
      >
        {children}
      </Paper>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default function DesignForm({
  isPortal = false,
}: {
  isPortal?: boolean;
}) {
  const history = useHistory();
  const query = useQuery();
  const integratedContext = useContext(IntegratedContext);

  const { data: designPricing } =
    useGetData<PricingWithDescription[]>("/api/pricing");

  const [size, setSize] = useState({ sizeInfoSize: "" } as DesignSize);
  const [name, setName] = useState("");
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [design, setDesign] = useState<Design>();
  const [isCreating, setIsCreating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [proofError, setProofError] = useState(false);
  const [proofs, setProofs] = useState<string[]>([]);
  const [formType, setFormType] = useState<"scratch" | "upload">("scratch");
  const [createPending, setCreatePending] = useState(false);
  const [pendingApproval, setPendingApproval] = useState(false);

  async function handleCreateClick() {
    setCreatePending(true);
    const token = getCookie("token") ?? undefined;
    const design = await postAsync<{ design: Design }>(
      `/designer`,
      {
        size: size.sizeInfoSize,
        name,
        isTemplate: false,
      },
      false,
      token,
    );

    if (design && design.design) {
      if (isPortal) {
        history.push(`/designs/designer/${design.design.designID}/edit`);
      } else {
        const origin = query.get("origin");
        history.push(
          `/integrated/editor/${design.design.designID}?token=${token}&origin=${origin}&source=${integratedContext.source}`,
        );
      }
    }
  }

  const onUploadComplete = (path: string, side: "front" | "back") => {
    if (side === "front") {
      setFront(path);
    } else {
      setBack(path);
    }
  };

  function createNewDesign() {
    setCreatePending(true);
    setIsCreating(true);
    // start uploading
    setIsUploading(true);
    fetchClient.post({
      path: "/designs/upload",
      data: {
        name,
        frontOfCard: `${window.location.origin}/uploads/${front}`,
        backOfCard: !["811", "BRO"].includes(size.sizeInfoSize)
          ? `${window.location.origin}/uploads/${back}`
          : "",
        proofFront: proofs[0],
        proofBack: !["811", "BRO"].includes(size.sizeInfoSize) ? proofs[1] : "",
        size: size.sizeInfoSize,
        documentId: size.documentId,
      },
      onSuccess: (design) => {
        if (isPortal) {
          history.push(`/designs/${design?.designID}`);
        } else {
          window.parent?.postMessage(
            {
              designID: design?.designID,
              envelopeType: "",
            },
            "*",
          );
        }
      },
    });
  }

  function generateDesignProof() {
    setProofError(false);
    setCreatePending(true);
    // start uploading
    setIsUploading(true);

    fetchClient.post({
      path: `/designs/generate-proof`,
      data: {
        size: size.sizeInfoSize,
        front: `${window.location.origin}/uploads/${front}`,
        back: `${window.location.origin}/uploads/${back}`,
        envelopeConfig: {
          type: size.sizeInfoSize === "811" ? "Regular" : "Bi",
        },
        recipient: {
          ...proofOrderRecipient,
          variables: proofOrderRecipient.recipientDesignVariables,
        },
      },
      onSuccess: onProofResponse,
      onError: handleProofFailure,
    });
  }

  function handleProofFailure() {
    setProofError(true);
    setIsUploading(false);
  }

  function onProofResponse(proofRes: {
    front?: string;
    back?: string;
    pdf?: string;
  }) {
    const proofImages: string[] = [];
    if (proofRes.front) {
      proofImages.push(proofRes.front);
    }
    if (proofRes.back) {
      proofImages.push(proofRes.back);
    }
    if (proofRes.pdf) {
      proofImages.push(proofRes.pdf);
    }

    // set proof state
    setProofs(proofImages);
    // stop uploading
    setIsUploading(false);
    // display proof approval
    setPendingApproval(true);
  }

  const handleSizeChange = (e: DesignSize) => {
    setSize(e);
  };

  function handleDeleteDesign() {
    // clear design state
    setDesign(undefined);
    setCreatePending(false);
    setPendingApproval(false);
    setProofs([]);
    setIsCreating(false);
    setProofError(false);
  }

  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: "1200px",
        margin: "auto",
        padding: 2,
      }}
    >
      {createPending}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <h1>Design Form</h1>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          {!isPortal && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                history.push(
                  `/integrated/gallery?token=${
                    getCookie("token") || undefined
                  }&origin=${origin}&source=${integratedContext.source}`,
                )
              }
            >
              <Typography
                component="span"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.primary,
                })}
              >
                Back
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      <FormWrapper isPortal={isPortal}>
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <FormGroup>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="designName"
                  name="designName"
                  label="Design Name"
                  placeholder="Design Name"
                  fullWidth
                  value={name}
                  disabled={createPending}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <SizeSelector
                  isPortal={isPortal}
                  source={integratedContext.source}
                  size={size}
                  onChange={handleSizeChange}
                  disabled={createPending}
                />
              </FormControl>
              <FormControl margin="normal">
                <FormLabel id="design-type">Design Type</FormLabel>
                <RadioGroup
                  aria-labelledby="design-type"
                  defaultValue="scratch"
                  name="deign-type"
                  value={formType}
                  onChange={(e) =>
                    setFormType(e.target.value as "scratch" | "upload")
                  }
                >
                  <FormControlLabel
                    value="scratch"
                    control={<Radio disabled={createPending} />}
                    label="Start a new design from scratch using our advanced design editor."
                  />
                  {(!size || size.sizeInfoSize !== "811SNP") && (
                    <FormControlLabel
                      value="upload"
                      control={<Radio disabled={createPending} />}
                      label="Upload your own print ready artwork."
                    />
                  )}
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth margin="normal">
                {formType === "upload" ? (
                  <React.Fragment>
                    {!pendingApproval && !proofError && (
                      <LoadingWrapper
                        loading={isUploading}
                        height={500}
                        message="Uploading your design and generating a proof. This could take a few minutes"
                      >
                        <Box>
                          {size.sizeInfoSize == "811" ? (
                            <GridRow>
                              <GridItem breakPoints={[12]}>
                                <Typography variant="h6" component="h3">
                                  Upload Your Letter
                                </Typography>
                                <ImageUpload
                                  pdfOnly
                                  useIframePreview
                                  onUploadSuccess={(path) =>
                                    onUploadComplete(path, "front")
                                  }
                                  helpText=""
                                  defaultImage={front}
                                />
                              </GridItem>
                            </GridRow>
                          ) : size.sizeInfoSize == "BRO" ? (
                            <GridRow>
                              <GridItem breakPoints={[12]}>
                                <Typography variant="h6" component="h3">
                                  Upload Your Brochure
                                </Typography>
                                <ImageUpload
                                  pdfOnly
                                  useIframePreview
                                  onUploadSuccess={(path) =>
                                    onUploadComplete(path, "front")
                                  }
                                  helpText="PDFs should be 2 pages, and in landscape orientation."
                                  defaultImage={front}
                                />
                              </GridItem>
                            </GridRow>
                          ) : (
                            <GridRow>
                              <GridItem breakPoints={[12, 6]}>
                                <Typography variant="h6" component="h3">
                                  Upload The Front
                                </Typography>
                                <ImageUpload
                                  onUploadSuccess={(path) =>
                                    onUploadComplete(path, "front")
                                  }
                                  helpText="PDF Images must be a single page"
                                  defaultImage={front}
                                />
                              </GridItem>
                              <GridItem breakPoints={[12, 6]}>
                                <Typography variant="h6" component="h3">
                                  Upload The Back
                                </Typography>
                                <ImageUpload
                                  onUploadSuccess={(path) =>
                                    onUploadComplete(path, "back")
                                  }
                                  helpText="PDF Images must be a single page"
                                  defaultImage={back}
                                />
                              </GridItem>
                            </GridRow>
                          )}

                          <Button
                            variant="contained"
                            color="primary"
                            disabled={
                              name.trim().length === 0 ||
                              front.trim().length === 0 ||
                              (size.sizeInfoSize != "811" &&
                                size.sizeInfoSize != "BRO" &&
                                back.trim().length === 0)
                            }
                            onClick={() => generateDesignProof()}
                          >
                            Generate Proof
                          </Button>
                        </Box>
                      </LoadingWrapper>
                    )}
                    {proofError && (
                      <LoadingWrapper loading={isUploading} height={500}>
                        <Box sx={{ textAlign: "center" }}>
                          <Typography variant="h4" component="h3">
                            There was an error generating your proof for
                            approval. Please try again
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => generateDesignProof()}
                          >
                            Generate Proof
                          </Button>
                        </Box>
                      </LoadingWrapper>
                    )}
                    {pendingApproval && !proofError && !isUploading && (
                      <LoadingWrapper
                        loading={isCreating}
                        height={500}
                        message="Approving your design"
                      >
                        <Typography variant="h4" component="h3">
                          Please approve your proof in order to finalize your
                          design
                        </Typography>
                        {size.sizeInfoSize == "811" ||
                        size.sizeInfoSize == "BRO" ? (
                          <iframe
                            src={proofs[0] + "#toolbar=0&epanes=0"}
                            style={{
                              width: "100%",
                              height: "500px",
                            }}
                          />
                        ) : (
                          <GridRow sx={{ py: 3 }}>
                            <GridItem breakPoints={[6]}>
                              <Img src={proofs[0]} />
                            </GridItem>
                            <GridItem breakPoints={[6]}>
                              <Img src={proofs[1]} />
                            </GridItem>
                          </GridRow>
                        )}
                        <GridRow>
                          <GridItem>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => createNewDesign()}
                            >
                              Approve Design
                            </Button>
                          </GridItem>
                          <GridItem>
                            <Button
                              color="primary"
                              onClick={handleDeleteDesign}
                            >
                              I need to make changes
                            </Button>
                          </GridItem>
                        </GridRow>
                      </LoadingWrapper>
                    )}
                  </React.Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={name.trim().length === 0}
                    onClick={handleCreateClick}
                  >
                    Create Design
                  </Button>
                )}
              </FormControl>
            </FormGroup>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              width: "300px",
            }}
          >
            {designPricing && size.sizeInfoSize ? (
              <PricingTable size={size.sizeInfoSize} pricing={designPricing} />
            ) : (
              <Box>Select a size to view pricing.</Box>
            )}
          </Box>
        </Box>
      </FormWrapper>
    </Box>
  );
}
