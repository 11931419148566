import { IBulletedList, Textbox } from "fabric";
import { Object as IObject } from "fabric/fabric-impl";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import tw, { css } from "twin.macro";
import { postAsync } from "../../../../helpers/asyncFetch";
import useQuery from "../../../../hooks/useQuery";
import { IntegratedContext } from "../../../../routes/Integrated/layout/IntegratedLayout";
import { IPenTool } from "../../../hooks/usePenTool/constants";
import useSaveData from "../../../hooks/useSaveData";
import FabricQrCode from "../../../models/types/FabricQrCode";
import { CanvasContext } from "../../../state/contexts/CanvasContext";
import { selectDesignInformation } from "../../../state/slices/designInformation";
import { selectLetterSettings } from "../../../state/slices/letterSettings";
import {
  selectQrCode,
  toggleQrCodeSelector,
} from "../../../state/slices/qrCode";
import { useDesignerDispatch, useDesignerSelector } from "../../../state/store";
import SaveDialog from "../SaveDialog/SaveDialog";
import ButtonReset from "../shared/ButtonReset";
import DesignerButton from "../shared/DesignerButton";
import EditorTooltip from "../shared/EditorTooltip";
import Modal, { ModalActions, ModalBody, ModalTitle } from "../shared/Modal";
import { InfoIcon, TrashIcon } from "../shared/SvgComponents";
import ToolButton from "../shared/ToolButton";
import BrochureProperties from "./BrochureProperties";
import BulletedListTopbar from "./BulletedListTopbar";
import GroupProperties from "./GroupProperties";
import ImageProperties from "./ImageProperties";
import LetterProperties from "./LetterProperties";
import QrCodeProperties from "./QrCodeProperties";
import RevertDialog from "./RevertDialog";
import SelectionProperties from "./SelectionProperties";
import ShapeProperties from "./ShapeProperties";
import TextProperties from "./TextProperties";
import toolbarStyles from "./toolbarStyles";
import DesignerProductTypes from "../../../../data/models/DesignerProductTypes";

interface ITopToolbarProps {
  sidebarIsOpen: boolean;
  selectedObject?: IObject;
  openFonts: () => void;
  penTool: IPenTool;
  toggleShortcuts: (disabled: boolean | string) => void;
  mode: string;
  // @todo Create a context for this that's more fluid instead of drilling it through from the initial canvas component.
  onSaveCallback?: (designID: number, envelopeType: string | undefined) => void;
  forceDisabled?: boolean;
}

const styles = {
  container: (sidebarIsOpen: boolean) => [
    tw`absolute h-12 bg-white border-b border-solid border-border top-0 right-0 z-50 pl-4 flex flex-col align-middle justify-center`,
    css`
      z-index: 100;
    `,
    sidebarIsOpen &&
      css`
        width: calc(100% - 384px);
      `,
    !sidebarIsOpen &&
      css`
        width: calc(100% - 64px);
      `,
  ],
  wrapper: [tw`flex h-full`],
  left: [tw`mr-auto mt-auto mb-auto`],
  right: [tw`ml-auto self-center pr-4`],
  saveButton: (disabled = false) => [
    tw`bg-blue px-8 h-full text-white font-semibold text-lg hover:bg-opacity-75 `,
    disabled &&
      tw`bg-buttonDisabled hover:bg-buttonDisabled text-buttonTextDisabled`,
  ],
  quitButton: (disabled = false) => [
    tw`bg-inputBackground px-8 h-full text-labels font-semibold text-lg hover:bg-opacity-75`,
    disabled &&
      tw`bg-buttonDisabled hover:bg-buttonDisabled text-buttonTextDisabled`,
  ],
  tooltip: [
    tw`bg-white rounded border border-solid border-border  w-96  text-center p-4 text-text text-sm`,
    css`
      z-index: 600;
    `,
  ],
  tooltipRow: [tw`flex items-center mb-1`],
  tooltipCol: [
    tw`w-1/2 pr-2 pl-2 text-left box-border text-xs last:text-right`,
  ],
  lastSaved: [tw`text-sm text-labels italic`],
};

const TopToolbar = ({
  sidebarIsOpen,
  selectedObject,
  openFonts,
  penTool,
  toggleShortcuts,
  mode,
  onSaveCallback,
  forceDisabled = false,
}: ITopToolbarProps) => {
  const integratedContext = useContext(IntegratedContext);
  const [saveOpen, setSaveOpen] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [revertOpen, setRevertOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [qrWarningOpen, setQrWarningOpen] = useState(false);
  const canvas = useContext(CanvasContext);
  const letterSettings = useDesignerSelector(selectLetterSettings);
  const dispatch = useDesignerDispatch();
  const designInformation = useDesignerSelector(selectDesignInformation);
  const qrCode = useDesignerSelector(selectQrCode);
  const history = useHistory();
  const query = useQuery();
  const params = useParams<{ designId: string }>();
  const isMac = getPlatform();
  const { saveAsync, getVariables, saveData } = useSaveData();
  const [lastSaved, setLastSaved] = useState("");

  const isLetter =
    designInformation.productType === DesignerProductTypes.LETTER;
  const isBrochure =
    designInformation.productType === DesignerProductTypes.BROCHURE;
  function getPlatform() {
    const userAgent = window.navigator.userAgent;
    return /Mac|Mac OS|MacIntel/gi.test(userAgent);
  }

  async function handleSaveKeys(e: KeyboardEvent) {
    if (penTool.penToolActive || penTool.isEditing) return;
    if (e.ctrlKey || e.metaKey) {
      if (e.key.toLowerCase() === "s") {
        e.preventDefault();
        await handleSave();
      }
    }
  }

  async function generateProof() {
    const variables = getVariables().map((x) => {
      return {
        key: x.name,
        value: `<<${x.name}>>`,
      };
    });
    const recipient = {
      firstName: "<<firstname>>",
      lastName: "<<lastname>>",
      address: "<<address>>",
      address2: "<<address2>>",
      city: "<<city>>",
      state: "<<state>>",
      zipCode: "<<zipcode>>",
      variables,
    };
    const returnAddress = {
      firstName: "<<returnaddressfirstname>>",
      lastName: "<<returnaddresslastname>>",
      address: "<<returnaddress>>",
      address2: "<<returnaddress2>>",
      city: "<<returnaddresscity>>",
      state: "<<returnaddressstate>>",
      zipCode: "<<returnaddresszipcode>>",
    };
    const envelopeConfig =
      designInformation?.canvasSettings?.width === 8.5 &&
      designInformation?.canvasSettings?.height === 11
        ? {
            type: letterSettings?.envelopeType ?? "Regular",
          }
        : null;

    await postAsync(`/designs/${params.designId}/generate-proof?save=true`, {
      recipient,
      returnAddress,
      htmlVersionId: designInformation?.htmlVersionID,
      envelopeConfig,
    });
  }

  async function handleIframeContinue() {
    if (qrCode.qrCodeId === -2) {
      setQrWarningOpen(true);
      return;
    }

    setIsSaving(true);
    setOverlay(true);
    await saveAsync();
    await generateProof();
    if (onSaveCallback) {
      if (params.designId) {
        const numId = parseInt(params.designId);
        onSaveCallback(numId, letterSettings?.envelopeType);
        setIsSaving(false);
        setOverlay(false);
      }
    }
  }

  function getObjectPropertyMenu() {
    if (selectedObject) {
      if (selectedObject.name?.includes("qrcode")) {
        return (
          <QrCodeProperties selectedObject={selectedObject as FabricQrCode} />
        );
      }
      if (selectedObject.name?.includes("image")) {
        return <ImageProperties selectedObject={selectedObject} />;
      }
      if (
        selectedObject.type === "activeSelection" ||
        (selectedObject.type === "group" &&
          selectedObject.name?.includes("group"))
      ) {
        return <SelectionProperties selectedObject={selectedObject} />;
      }
      if (selectedObject.name?.includes("bulletedList")) {
        return (
          <BulletedListTopbar
            list={selectedObject as IBulletedList}
            openFonts={openFonts}
            openIcons={() => {}}
          />
        );
      }
      if (selectedObject.type === "group" || !selectedObject.name)
        return <GroupProperties selectedObject={selectedObject} />;

      if (selectedObject.type?.includes("text")) {
        return (
          <TextProperties
            textObj={selectedObject as Textbox}
            openFonts={openFonts}
          />
        );
      }
      return (
        <ShapeProperties selectedObject={selectedObject} penTool={penTool} />
      );
    }

    if (penTool.isEditing && penTool.targetPath)
      return (
        <ShapeProperties
          selectedObject={penTool.targetPath}
          penTool={penTool}
        />
      );
    return <div />;
  }

  function handleQrClick() {
    setQrWarningOpen(false);
    dispatch(toggleQrCodeSelector());
  }

  function handleBackToDesigns() {
    const token = query.get("token");
    if (token) {
      history.push(
        `/integrated/gallery?token=${token}&source=${integratedContext.source}`,
      );
    } // const token = search.["token"];
  }

  async function handleSave() {
    setOverlay(true);
    toggleShortcuts("save");
    saveCanvas();
  }

  async function saveCanvas() {
    await saveAsync();
    setOverlay(false);
    const date = new Date();
    toggleShortcuts(false);
    setLastSaved(moment(date).format("h:mm a"));
  }

  useEffect(() => {
    window.addEventListener("keydown", handleSaveKeys);
    return () => {
      window.removeEventListener("keydown", handleSaveKeys);
    };
  }, []);

  return (
    <React.Fragment>
      {overlay && (
        <div
          css={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1000,
            background: "rgba(0,0,0,.5)",
          }}
        ></div>
      )}

      <div css={styles.container(sidebarIsOpen)}>
        <div css={styles.wrapper}>
          <div css={styles.left}>
            {penTool.penToolActive && !penTool.isEditing && (
              <ToolButton
                onClick={penTool.discardDrawing}
                css={toolbarStyles.utilityButton}
              >
                <TrashIcon size={5} />
              </ToolButton>
            )}
            {!selectedObject && !penTool.penToolActive && isLetter && (
              <LetterProperties />
            )}
            {!selectedObject && !penTool.penToolActive && isBrochure && (
              <BrochureProperties />
            )}
            {getObjectPropertyMenu()}
          </div>

          <div css={[styles.right, styles.lastSaved]}>
            {lastSaved ? `Last saved ${lastSaved}` : ""}
          </div>
          <div tw="self-center mr-2">
            <EditorTooltip label={<InfoIcon size={5} />} enableLock>
              <div css={styles.tooltip}>
                <div css={tw`mb-2`}>
                  {penTool.penToolActive
                    ? "Pen Tool Modifiers"
                    : "Keyboard Shortcuts"}
                </div>
                {penTool.penToolActive && (
                  <React.Fragment>
                    <div
                      css={css`
                        text-align: left;
                      `}
                    >
                      <div css={tw`font-semibold mb-0.5`}>Shift Key</div>
                      <div css={tw`text-sm mb-1`}>
                        Add a new point to the current path or remove an
                        existing point. When adjusting a curve, the shift key
                        will break the connection to its inverse curve handle.
                      </div>
                      <div css={tw`font-semibold mb-0.5`}>Alt Key</div>
                      <div>
                        Click a curved point to transition its forward curve to
                        a straight line. Click the curve point again to remove
                        the curve entirely. If the point has no curve, add one.
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {!penTool.penToolActive && (
                  <React.Fragment>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Zoom</div>
                      <div css={styles.tooltipCol}>ctrl+scroll</div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Pan</div>
                      <div css={styles.tooltipCol}>hold spacebar</div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Delete</div>
                      <div css={styles.tooltipCol}>del</div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Copy </div>
                      <div css={styles.tooltipCol}>
                        {isMac ? "cmd" : "ctrl"}+c
                      </div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Paste</div>
                      <div css={styles.tooltipCol}>
                        {isMac ? "cmd" : "ctrl"}+v
                      </div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Paste In Place</div>
                      <div css={styles.tooltipCol}>
                        {isMac ? "cmd" : "ctrl"}+shift+v
                      </div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Undo</div>
                      <div css={styles.tooltipCol}>
                        {isMac ? "cmd" : "ctrl"}+z
                      </div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Redo</div>
                      <div css={styles.tooltipCol}>
                        {isMac ? "cmd" : "ctrl"}+shift+z
                      </div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Save</div>
                      <div css={styles.tooltipCol}>
                        {isMac ? "cmd" : "ctrl"}+s
                      </div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Move</div>
                      <div css={styles.tooltipCol}>arrow keys</div>
                    </div>
                    <div css={styles.tooltipRow}>
                      <div css={styles.tooltipCol}>Deselect</div>
                      <div css={styles.tooltipCol}>esc</div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </EditorTooltip>
          </div>

          <div>
            {(mode === "internal" ||
              mode === "admin" ||
              mode === "portalCustomer") && (
              <div css={tw`h-full`}>
                <ButtonReset
                  onClick={handleSave}
                  css={styles.saveButton(penTool.penToolActive)}
                  disabled={forceDisabled ? true : penTool.penToolActive}
                >
                  Save
                </ButtonReset>
                <ButtonReset
                  onClick={() => setSaveOpen(true)}
                  css={styles.quitButton(penTool.penToolActive)}
                  disabled={forceDisabled ? true : penTool.penToolActive}
                >
                  Quit
                </ButtonReset>
                {Boolean(designInformation.initialJson) && (
                  <ButtonReset
                    onClick={() => setRevertOpen(true)}
                    css={styles.quitButton(penTool.penToolActive)}
                    disabled={forceDisabled ? true : penTool.penToolActive}
                  >
                    Reset Design
                  </ButtonReset>
                )}
              </div>
            )}
            {(mode === "iframe" || mode === "embed") && (
              <div css={tw`h-full`}>
                <ButtonReset
                  onClick={handleIframeContinue}
                  css={styles.saveButton(
                    penTool.penToolActive ? true : isSaving ? true : false,
                  )}
                  disabled={
                    forceDisabled ? true : penTool.penToolActive || isSaving
                  }
                >
                  Save &amp; Continue
                </ButtonReset>
                {mode !== "embed" && (
                  <ButtonReset
                    onClick={handleBackToDesigns}
                    css={styles.quitButton(penTool.penToolActive)}
                    disabled={forceDisabled ? true : penTool.penToolActive}
                  >
                    Back To Designs
                  </ButtonReset>
                )}
                {Boolean(designInformation.initialJson) && (
                  <ButtonReset
                    onClick={() => setRevertOpen(true)}
                    css={styles.quitButton(penTool.penToolActive)}
                    disabled={forceDisabled ? true : penTool.penToolActive}
                  >
                    Reset Design
                  </ButtonReset>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {saveOpen && (
        <SaveDialog
          onClose={() => setSaveOpen(false)}
          isOpen={saveOpen}
          mode={mode}
        />
      )}
      {revertOpen && (
        <RevertDialog
          onClose={() => setRevertOpen(false)}
          isOpen={revertOpen}
        />
      )}
      <Modal isOpen={qrWarningOpen} onClose={() => setQrWarningOpen(false)}>
        <ModalTitle>Configure Your QR Code</ModalTitle>
        <ModalBody>
          <div>
            <p>
              This design features a QR Code that must be configured before you
              may proceed.
            </p>
          </div>
        </ModalBody>
        <ModalActions>
          <DesignerButton onClick={handleQrClick}>
            Configure QR Code
          </DesignerButton>{" "}
          <DesignerButton
            onClick={() => setQrWarningOpen(false)}
            css={tw`bg-white text-blue hover:bg-white`}
          >
            Return To Editor
          </DesignerButton>
        </ModalActions>
      </Modal>
    </React.Fragment>
  );
};

export default TopToolbar;
